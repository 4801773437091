// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host {
  height: 100%;
}
:host * {
  box-sizing: border-box;
}
:host .hidden {
  display: none;
}
:host .animal-container {
  height: 100%;
  overflow: hidden;
  display: flex;
  justify-content: center;
}
:host .animal-container app-animal-controls {
  position: absolute;
  width: 100%;
  height: 100%;
}
:host .animal-container canvas {
  height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/gamification/core/animal-canvas/animal-canvas.component.scss","webpack://./../APP%20Humanum/src/app/@modules/gamification/core/animal-canvas/animal-canvas.component.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;ACCJ;ADCI;EACI,sBAAA;ACCR;ADEI;EACI,aAAA;ACAR;ADGI;EACI,YAAA;EACA,gBAAA;EACA,aAAA;EACA,uBAAA;ACDR;ADGQ;EACI,kBAAA;EACA,WAAA;EACA,YAAA;ACDZ;ADIQ;EACI,YAAA;ACFZ","sourcesContent":[":host {\n    height: 100%;\n\n    * {\n        box-sizing: border-box;\n    }\n\n    .hidden {\n        display: none;\n    }\n\n    .animal-container {\n        height: 100%;\n        overflow: hidden;\n        display: flex;\n        justify-content: center;\n\n        app-animal-controls {\n            position: absolute;\n            width: 100%;\n            height: 100%;\n        }\n\n        canvas {\n            height: 100%;\n        }\n    }\n}",":host {\n  height: 100%;\n}\n:host * {\n  box-sizing: border-box;\n}\n:host .hidden {\n  display: none;\n}\n:host .animal-container {\n  height: 100%;\n  overflow: hidden;\n  display: flex;\n  justify-content: center;\n}\n:host .animal-container app-animal-controls {\n  position: absolute;\n  width: 100%;\n  height: 100%;\n}\n:host .animal-container canvas {\n  height: 100%;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
