import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FlashcardAttribute} from "shared/flashcard";

@Component({
    selector: 'app-dictionary-card',
    templateUrl: './dictionary-card.component.html',
})
export class DictionaryCardComponent {
    @Output() state = new EventEmitter<string>();
    @Output() openCard = new EventEmitter<boolean>();
    @Input() flashCard!: {id?: string | number} & FlashcardAttribute;
    @Input() idCardPlaying = '';

    constructor() {
    }

    public cardClick() {
        this.state.emit('card-click');
    }

    public isPlaying(): boolean {
        return this.idCardPlaying && this.idCardPlaying !== '' && this.idCardPlaying === this.flashCard.id;
    }

    public openCardActivity(): void{
        this.openCard.emit(true);
    }

}


