// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `:host .content form {
  width: 100%;
  max-width: 800px !important;
}
:host .content .form-errors-model {
  flex: 1;
}
:host .content .form-errors-model code {
  background: none !important;
}
:host .content .horizontal-stepper-wrapper,
:host .content .vertical-stepper-wrapper {
  max-width: 800px;
}`, "",{"version":3,"sources":["webpack://./src/app/@modules/account-management/core/profile/tabs/edit-profile/forms.component.scss","webpack://./../APP%20Humanum/src/app/@modules/account-management/core/profile/tabs/edit-profile/forms.component.scss"],"names":[],"mappings":"AAIQ;EACI,WAAA;EACA,2BAAA;ACHZ;ADMQ;EACI,OAAA;ACJZ;ADMY;EACI,2BAAA;ACJhB;ADQQ;;EAEI,gBAAA;ACNZ","sourcesContent":[":host {\n\n    .content {\n\n        form {\n            width: 100%;\n            max-width: 800px !important;\n        }\n\n        .form-errors-model {\n            flex: 1;\n\n            code {\n                background: none !important;\n            }\n        }\n\n        .horizontal-stepper-wrapper,\n        .vertical-stepper-wrapper {\n            max-width: 800px;\n        }\n    }\n}",":host .content form {\n  width: 100%;\n  max-width: 800px !important;\n}\n:host .content .form-errors-model {\n  flex: 1;\n}\n:host .content .form-errors-model code {\n  background: none !important;\n}\n:host .content .horizontal-stepper-wrapper,\n:host .content .vertical-stepper-wrapper {\n  max-width: 800px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
