import {environment} from 'environments/environment';

export const mainMenu = {
    menu: {
        default: [],
        learner: [
            {
                label: 'generic.menu-play',
                path: '/lessons/home',
                icon: 'planet',
                options: {
                    customClasses: ['menu-play'],
                },
            },
            {
                label: 'generic.menu-assignments',
                path: '/followed/my-assignments',
                icon: 'assignment_menu',
            },
            {
                label: 'generic.menu-dictionary',
                path: '/activities/dictionary',
                icon: 'book',
            },
            {
                label: 'generic.language-lab',
                path: '/activities/language-lab',
                icon: 'keyboard_voice',
            },
            {
                label: 'generic.my_avatar',
                path: '/my-avatar',
                icon: 'account_circle',
            }
        ],
        trainer: [
            {
                label: 'generic.menu-play',
                path: '/lessons/home',
                icon: 'planet',
                options: {
                    customClasses: ['menu-play'],
                },
            },
            {
                label: 'generic.menu-assignments',
                path: '/followed/my-assignments',
                icon: 'assignment_menu',
            },
            {
                label: 'generic.menu-dictionary',
                path: '/activities/dictionary',
                icon: 'book',
            },
            {
                label: 'generic.language-lab',
                path: '/activities/language-lab',
                icon: 'keyboard_voice',
            },
            {
                label: 'generic.my_avatar',
                path: '/my-avatar',
                icon: 'account_circle',
            }
        ],
    },
    sideNavMode: 'over',
    isOpenedByDefault: false,
    externalLinkWithLicense: {
        checkIfUserHasInstitutionLicense: false,
        checkIfUserHasClassLicense: false,
        checkIfUserHasFreeLicense: true,
        externalLink: {
            label: 'navigation.external-target-link-menu',
            path: () => `${environment.basePlatformPath}/user/reset/${localStorage.getItem('http_accessToken')?.slice(1, -1)}`,
            externalUrl: true,
            openInNewTab: true,
            options: {
                customClasses: ['wrapper-menu-item-external-target-link-menu'],
            },
            icon: 'logout',
        },
    },
};