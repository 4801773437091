export class SvgIcon {
    [key: string]: string
}

export const SVGICONTHEME: SvgIcon = {
    'main_menu': 'lg-menu',
    'close': 'lg-close',
    'cross': 'lg-cross',
    'help': 'front_hand_FILL1_wght700_GRAD0_opsz20--b10745',
    'arms': 'arms',
    'allTypes': 'toustypes',
    'approval-delegation-rounded-fill': 'approval_delegation_rounded_fill',
    'archive': 'archiver',
    'arrow-drop-down-rounded-700': 'arrow_drop_down_rounded_700',
    'arrow_left_circle_outline': 'arrow_left_circle_outline',
    'arrow_right_circle_outline': 'arrow_right_circle_outline',
    'arrow_left': 'arrow-left',
    'arrow_right': 'arrow_right',
    'assignment_ind': 'assignment_ind',
    'assignmentsCardIcon': 'arrow-right',
    'back': 'undo_rounded',
    'logout': 'logout',
    'bodies': 'bodies',
    'brand': 'brand',
    'brand_square': 'brand',
    'btn_trash': 'trash_tiny',
    'btn_log_out': 'log_out_tiny',
    'check': 'check_rounded_700',
    'check-circle-rounded': 'check_circle_rounded',
    'check-circle-rounded-fill-600': 'check_circle_rounded_fill_600',
    'chevron': 'chevron',
    'class_add': 'add',
    'clothes': 'clothes',
    'conceptCardIcon': 'arrow-right',
    'contact': 'contact',
    'communication': 'communication',
    'crown': 'crown',
    'done': 'check_rounded_700',
    'editor-play': 'volume_up_fill_700',
    'trash': 'trash',
    'cross_outline': 'cross_outline',
    'dances': 'dances',
    'delete': 'trash',
    'diamond': 'diamond',
    'document': 'document',
    'drag_indicator': 'arrow_down_circle_outline',
    'dual_arrow': 'dual_arrow',
    'edit': 'edit',
    'ears': 'ears',
    'east': 'east',
    'eyes': 'eyes',
    'equalizer': 'equalizer',
    'file_download': 'file_download',
    'fullscreen': 'fullscreen_rounded_700',
    'duplicate': 'duplicate',
    'file': 'corpus',
    'fill-in-blanks': 'remplir_blancs',
    'false': 'block_FILL1_wght400_GRAD0_opsz48',
    'groups-form-add': 'check_rounded_700',
    'hats': 'hats',
    'icon-arrow-back': 'arrow_back',
    'image__button-fullscreen': 'fullscreen_rounded_700',
    'institution_add': 'add',
    'legs': 'legs',
    'lessons': 'transition',
    'lessonCardIcon': 'arrow-right',
    'link': 'url',
    'lock': 'lock',
    'lock-fill': 'lock_fill',
    'lock-open-fill': 'lock_open_fill',
    'mouths': 'mouths',
    'next': 'arrow_right_alt_rounded',
    'output-rounded': 'output_rounded',
    'pdf': 'ressources',
    'play': 'play',
    'play-arrow-rounded-fill-700': 'play_arrow_rounded_fill_700',
    'play_button': 'play_button',
    'picto_math': 'picto_math',
    'puce': 'puce',
    'qcm__register__null': 'check_rounded_700',
    'quit': 'reply_all_rounded',
    'reload': 'reload',
    'remove-selection-rounded': 'remove_selection_rounded',
    'retry': 'refresh_rounded_alt',
    'round-videoprojeter': 'round_videoprojeter',
    'round-videoprojeteroff': 'round_videoprojeteroff',
    'save': 'save',
    'shield': 'confi',
    'skip': 'reply_rounded',
    'sound_on': 'volume_up_fill_700',
    'sound_off': 'volume_down',
    'speaker-equalizer': 'speaker_equalizer',
    'star': 'star',
    'stars': 'stars',
    'step-done': 'step_done',
    'step-inprogress': 'step_inprogress',
    'step-pasfait': 'step_pasfait',
    'student_add': 'check_circle_outline',
    'trainer_add': 'add',
    'true': 'download_done_FILL1_wght400_GRAD0_opsz48',
    'transition': 'transition',
    'unarchive': 'desarchiver',
    'unassign': 'unassign',
    'uncheck-circle-fill': 'radio_button_unchecked_fill',
    'unlock': 'unlock',
    'visibility': 'visibility',
    'visibility_legalnotice': 'visibility',
    'visibility_cgurgpd': 'cgu',
    'visibility_privacy': 'privacy',
    'workgroup_add': 'add',
    'xxx': 'dislike',
    'checked_button': 'checked_button',
    'unchecked_button': 'unchecked_button',
    'equalizer_1': 'equalizer_1',
    'equalizer_2': 'equalizer_2',
    'equalizer_3': 'equalizer_3',
    'equalizer_4': 'equalizer_4',
    'planet': 'planet',
    'assignment_menu': 'assignment_menu',
    'coin': 'coin',
    'assignment_period_list': 'assignment_period_list',
    'flag_period_list': 'flag_period_list',
    'book': 'book',
    'keyboard_voice': 'keyboard_voice',
    'overview_key': 'overview_key',
    'headphones': 'headphones_fill',
    'stop': 'stop_rounded_fill',
    'bookmark-added': 'bookmark_added',
    'account_circle': 'account_circle',
    'button-audio__icon': 'equalizer_1',
    'accentuation de mots': 'accentuation_mots',
    'accentuation de phrases': 'accentuation_phrases',
    'accentuation de syllabes': 'accentuation_syllabe',
    'dialogue': 'conversation',
    'conversation': 'conversation',
    'lexique': 'lexique',
    'phonologie': 'phonologie',
    'question_mark': 'question_mark',
};