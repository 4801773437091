export const gamification = {
    showRewards: true,
    badgeTypes: [ // Liste des badges types (taxonomies à charger depuis le drupal)
        'Corps', // Corps
        'Accessoire', // Accessoire
        'Haut', // Haut
        'Dance', // Danse
        'Bras', // Bras
        'Yeux', // Yeux
        'Oreilles', // Oreilles
        'Bouche', // Bouche
        'Jambes', // Jambes
    ],
    badgeImageIcon: [ // List of badge icons
        'clothes', // Accessoire
        'legs', // Jambes
        'eyes', // Yeux
        'mouths', // Bouche
        'bodies', // Corps
        'hats', // Haut
        'ears', // Oreilles
        'arms', // Bras
        'dances', // Danse
    ],
    badgeImageIconBigger: [ // These badge icons need a different class to be properly styled in ubolino_app
        'clothes', // Accessoire
        'hats', // Haut
        'dances', // Danse
        'ears', // Oreilles
    ],
    badgeTypeAnimations: [ // Badges Types n'étant pas des skins mais des animations
        'Dance'
    ],
    defaultSkinName: 'S_DEFAULT',
    defaultAnimationName: 'dance_01', // Animation par défaut à charger
    skeletonAtlas: './assets/langues_app/animations/AVATAR_01/AVATAR.atlas',
    skeletonJson: './assets/langues_app/animations/AVATAR_01/AVATAR.json'
};