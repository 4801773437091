import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FuseDirectivesModule } from '@fuse/directives/directives';
import { FusePipesModule } from '@fuse/pipes/pipes.module';
import {FuseSidebarModule} from 'fuse-core/components';
import {GraphModule} from 'fuse-core/components/graph/graph.module';
import { FlashcardComponent } from './components/flashcard/flashcard.component';
import {SharedMaterialModule} from '../app/shared/shared-material.module';
import { ReplaceTextPipe } from 'fuse-core/pipes/replace-text.pipe';
import {SharedVideoComponent} from 'fuse-core/components/shared-video/shared-video.component';
import {TranslateModule} from '@ngx-translate/core';
import { DictionaryCardComponent } from './components/dictionary-card/dictionary-card.component';
import {TipsComponent} from 'fuse-core/components/tips/tips.component';
import { HelpLessonsDialogComponent } from 'fuse-core/components/help-lessons-dialog/help-lessons-dialog.component';
import { HelpDictionaryDialogComponent } from './components/helpdictionary-dialog/help-dictionary-dialog.component';
import {MAT_LEGACY_DIALOG_DATA as MAT_DIALOG_DATA} from '@angular/material/legacy-dialog';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FusePipesModule,
        FuseSidebarModule,
        GraphModule,
        SharedMaterialModule,
        TranslateModule,
    ],
    exports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        FlexLayoutModule,
        FuseDirectivesModule,
        FuseSidebarModule,
        GraphModule,
        FlashcardComponent,
        ReplaceTextPipe,
        SharedVideoComponent,
        DictionaryCardComponent,
        HelpLessonsDialogComponent,
        HelpDictionaryDialogComponent,
        TipsComponent,
    ],
    declarations: [
        FlashcardComponent,
        ReplaceTextPipe,
        SharedVideoComponent,
        DictionaryCardComponent,
        TipsComponent,
        HelpLessonsDialogComponent,
        HelpDictionaryDialogComponent,
    ],
    providers: [
        ReplaceTextPipe,
        { provide: MAT_DIALOG_DATA, useValue: {} }
    ],
})
export class FuseSharedModule
{
}
