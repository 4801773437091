import {ActivatedRoute, Router} from "@angular/router";
import {DataEntity, OctopusConnectService} from "octopus-connect";
import {
    MatLegacyDialog as MatDialog,
    MatLegacyDialogConfig as MatDialogConfig,
    MatLegacyDialogRef as MatDialogRef,
} from "@angular/material/legacy-dialog";
import {ModelSchema, Structures} from "octopus-model";
import {Observable, ReplaySubject, of} from "rxjs";
import {
    UpdateMailDialogComponent,
    UpdateMailDialogDataInterface,
} from "@modules/authentication/core/update-mail-dialog/update-mail-dialog.component";
import {catchError, map, mergeMap, take, tap} from "rxjs/operators";
import {defaultLoginRoute, modulesSettings} from "../../../settings";

import {AccessLevel} from "shared/models";
import {CommunicationCenterService} from "@modules/communication-center";
import {FuseConfirmDialogComponent} from "fuse-core/components/confirm-dialog/confirm-dialog.component";
import {Injectable} from "@angular/core";
import {QuickConnectUserType} from "@modules/authentication/core/models/quick-connect-user.type";
import {TranslateService} from "@ngx-translate/core";
import {UserDataEntity} from "@modules/authentication/core/models/user-data-entity.type";
import {currentTimestamp} from "shared/utils/datetime";

const settingsStructureAuth: ModelSchema = new ModelSchema({
    canAccessToAuthenticationFeatures: Structures.boolean(true),
    displayLoginLogo: Structures.boolean(false),
    enableSSO: Structures.boolean(false),
    firstConnexionRedirection: Structures.object({}),
    forceSetEmail: Structures.boolean(false),
    logoutUrlSSO: Structures.object(),
    urlSSO: Structures.object(),
    validateEmailStrategyActivated: Structures.boolean(false),
    rolesAllowedToLogin: Structures.array([2, 3, 4, 5, 6]),
    registerPath: Structures.string("/register"),
    quickConnect: Structures.boolean(false),
    enableCode: Structures.boolean(false),
    activeChangePasswordStrategy: Structures.boolean(false),
});

const settingsStructureAccount: ModelSchema = new ModelSchema({
    selfSignup: Structures.boolean(true),
    signup: Structures.boolean(true),
});

@Injectable()
export class AuthenticationService {
    /**
     * Mapping between role name and role id.
     * It can be given by the server but in all the instance we work to always use the same name/id combination
     */
    private static readonly roleMapping = {
        administrator: 3,
        manager: 4,
        trainer: 5,
        learner: 6,
    };

    loggedUser: DataEntity;
    isAuthenticated = false;
    subject: ReplaySubject<boolean> = new ReplaySubject<boolean>(1);

    errorHttpAuthentication: ReplaySubject<any> = new ReplaySubject<any>(1);
    public settings: { [key: string]: any };
    public renewPassword = false;

    constructor(
        private octopusConnect: OctopusConnectService,
        private router: Router,
        private translate: TranslateService,
        private communicationCenter: CommunicationCenterService,
        private dialog: MatDialog,
        private route: ActivatedRoute
    ) {
        this.settings = settingsStructureAuth.filterModel(
            modulesSettings.authentication
        );
        Object.assign(
            this.settings,
            settingsStructureAccount.filterModel(modulesSettings.accountManagement)
        );
        this.octopusConnect.getUnexpectedLogoutSubject("http").subscribe(
            () => {
                this.doLogout(null);
            },
            (error: Object) => {
                this.errorHttpAuthentication.next(error);
            }
        );

        this.communicationCenter
            .getRoom("authentication")
            .getSubject("do-logout")
            .subscribe((callback) => {
                this.logoutFrom("http");
                callback();
            });

        this.communicationCenter
            .getRoom("authentication")
            .next("roles", AuthenticationService.roleMapping);

        this.communicationCenter
            .getRoom("authentication")
            .getSubject("goToLogin")
            .subscribe(() => this.goToLogin());

        this.communicationCenter
            .getRoom("authentication")
            .getSubject("connectAfterValidatedAccountByEmail")
            .subscribe((data) => this.connectionAfterValidatingEmail(data));
    }

    get userData(): null | DataEntity {
        if (this.loggedUser) {
            this.isAuthenticated = true;
            return this.loggedUser;
        }

        return null;
    }

    get accessLevel(): AccessLevel {
        if (this.loggedUser) {
            const role = this.loggedUser.get("role") || [];

            if (role.indexOf(3) > -1) {
                return "administrator";
            }
            if (role.indexOf(4) > -1) {
                return "manager";
            }
            if (role.indexOf(5) > -1) {
                return "trainer";
            }
            if (role.indexOf(6) > -1) {
                return "learner";
            }
            if (role.indexOf(2) > -1) {
                return "authenticated";
            }
        }

        return "anonymous";
    }

    loginSSO(code): void {
        this.octopusConnect
            .createEntity("user-registration", {code: code})
            .subscribe(
                (userData: DataEntity) => {
                    if (userData && userData.get("token")) {
                        this.router.navigate(["/user/reset/", userData.get("token")]);
                    }
                },
                (error: Object) => {
                    this.errorHttpAuthentication.next(error);
                }
            );
    }

    authenticateIn(
        serviceName: string,
        login: string,
        password: string
    ): Observable<UserDataEntity> {
        return this.octopusConnect
            .authenticate(
                serviceName,
                unescape(encodeURIComponent(login)),
                unescape(encodeURIComponent(password))
            )
            .pipe(
                take(1),
                mergeMap((user: UserDataEntity) => {
                    let obs = of(user);

                    // store user in local storage for quick connect
                    if (this.settings.quickConnect) {
                        this.addQuickConnectUser(user);
                    }

                    // if validating email is need before connecting user
                    if (
                        this.settings.validateEmailStrategyActivated &&
                        !user.get("email_status")
                    ) {
                        return obs;
                    }

                    if (
                        this.settings.forceSetEmail &&
                        user.get("updateProfile") === true
                    ) {
                        obs = obs.pipe(tap((subUser) => this.askForMailUpdate(subUser)));
                    }

                    return obs.pipe(tap((subUser) => this.onAuthenticated(subUser)));
                }),
                catchError((error) => {
                    this.errorHttpAuthentication.next(error);
                    throw error;
                })
            );
    }

    onAuthenticated(data: DataEntity): void {
        this.loggedUser = data;
        this.isAuthenticated = true;
        this.communicationCenter.getRoom("authentication").next("userData", data);
    }

    public isMe(id: string | number): boolean {
        if (this.loggedUser) {
            return this.loggedUser.id.toString() === id.toString();
        }

        return false;
    }

    public isAnonymous(): boolean {
        return this.accessLevel === "anonymous";
    }

    public isAuthenticatedUser(): boolean {
        return this.loggedUser.get("role").indexOf(2) > -1;
    }

    public isSSO(): boolean {
        return this.loggedUser.get("sso");
    }

    public isGAR(): boolean {
        return (this.isSSO() && this.settings.enableGAR) || this.settings.forceGAR;
    }

    public isLearner(): boolean {
        return this.accessLevel === "learner";
    }

    public isAtLeastLearner(): boolean {
        return this.hasLevel(["learner", "trainer", "manager", "administrator"]);
    }

    public isTrainer(): boolean {
        return this.accessLevel === "trainer";
    }

    public isAtLeastTrainer(): boolean {
        return this.hasLevel(["trainer", "manager", "administrator"]);
    }

    public isAtLeastParent(): boolean {
        return this.hasLevel(['parent', 'trainer', 'director', 'manager', 'administrator']);
    }

    public isManager(): boolean {
        return this.accessLevel === "manager";
    }

    public isAtLeastManager(): boolean {
        return this.hasLevel(["manager", "administrator"]);
    }

    public isAdministrator(): boolean {
        return this.accessLevel === "administrator";
    }

    public hasLevel(levels: string[]): boolean {
        return levels.indexOf(this.accessLevel) > -1;
    }

    logoutFrom(serviceName: string): void {
        let token;
        if (this.settings.enableSSO && this.loggedUser.get("sso")) {
            token = this.loggedUser.get("sso_token");
        }

        const data = new DataEntity(
            "authenticated",
            {myType: "authenticated"},
            this.octopusConnect,
            this.loggedUser["id"]
        );
        data.remove();

        this.octopusConnect.logout(serviceName).subscribe(() => {
            this.doLogout(token);
        });
    }

    forgotPassword(login: string): Observable<DataEntity> {
        return this.octopusConnect.createEntity("reset-password", {
            mail: login,
            lang: this.translate.currentLang,
        });
    }

    public goToLogin(state?): void {
        if (state && state.url !== "/logout") {
            this.router.navigate(["/connect"], {
                queryParams: {
                    return: state.url,
                },
            });
        } else {
            this.router.navigate(["/connect"]);
        }
    }

    /**
     * Return true if the currently connected user is connected for the very first time.
     * @remarks If the user is not logged return false
     */
    public isFirstConnexion(): boolean {
        return !!this.userData && this.userData.get("first_access");
    }

    /**
     * fire a send of email to validate
     * @param email : email of user who hasn't validate email before
     */
    sendNewLinkEmailValidation(email: string): Observable<any> {
        return this.octopusConnect.createEntity("user-registration", {
            email: email,
            sendValidationMail: true,
        });
    }

    protected doLogout(token: any): void {
        this.isAuthenticated = false;
        this.communicationCenter.getRoom("authentication").next("userData", null);
        this.goToLogin();
    }

    private askForMailUpdate(
        subUser: UserDataEntity
    ): MatDialogRef<UpdateMailDialogComponent> {
        return this.dialog.open<
            UpdateMailDialogComponent,
            UpdateMailDialogDataInterface
        >(UpdateMailDialogComponent, {
            data: {
                updateMail: (mail) => {
                    if (!!mail) {
                        subUser.set("email", mail);
                        return subUser.save().pipe(
                            map(() => null),
                            catchError((err) => of({error: err.message}))
                        );
                    }

                    throw new Error("no mail given");
                },
            },
        });
    }

    /**
     * if user create account when he validate email he s connected without
     * type password because he directly use token so we init data like if he was connected by
     * login or token in standard process
     * @param userData : DataEntity contain user data
     * @private
     */
    private connectionAfterValidatingEmail(userData: DataEntity): void {
        this.loggedUser = userData;
        this.isAuthenticated = true;
    }

    public deleteQuickConnectUsers(localUsers, userLabel): void {
        const i = localUsers.findIndex(
            (localUser) => localUser.label === userLabel
        );
        if (i !== -1) {
            localUsers.splice(i, 1);
            localStorage.setItem("localUsers", JSON.stringify(localUsers));
        }
    }

    public addQuickConnectUser(user): void {
        // Parse any JSON previously stored in users
        const localUsers = this.getQuickConnectUsers();

        const localUser: QuickConnectUserType = {
            label: user.get("label"),
            codeid: user.get("codeid"),
            role: user.get("role"),
            nickname: user.get("nickname"),
        };
        // Save localUsers back to local storage if not exists
        if (this.quickConnectUserExists(user.get("label"), localUsers) === false) {
            localUsers.push(localUser);
            localStorage.setItem("localUsers", JSON.stringify(localUsers));
        }
    }

    private quickConnectUserExists(label, usersArray): boolean {
        return (
            usersArray.length > 0 && usersArray.some((user) => user.label === label)
        );
    }

    public getQuickConnectUsers(): any {
        let localUsers: QuickConnectUserType[] = JSON.parse(
            localStorage.getItem("localUsers")
        );
        if (localUsers === null) {
            localUsers = [];
        }
        return localUsers;
    }

    public login(loginValue, passwordValue, loginForm?): void {
        this.authenticateIn("http", loginValue, passwordValue)
            .pipe(take(1))
            .subscribe((user: UserDataEntity) => {
                let isAllowed = false;
                // check if the user's role is allowed to login
                isAllowed = this.settings.rolesAllowedToLogin.some((roleId: number) =>
                    user.get("role").includes(roleId)
                );

                if (isAllowed) {
                    if (
                        this.settings.validateEmailStrategyActivated &&
                        !user.get("email_status")
                    ) {
                        this.logoutFrom("http");
                        return;
                    }

                    // set value is need for pass form on valid state
                    if (loginForm) {
                        loginForm.controls.login.setValue(loginForm.value["login"]);

                        if (this.isNewPasswordToSet(loginForm)) {
                            this.updatePassword(loginForm);
                            return;
                        }
                    }
                    // must be after condition this.isNewPasswordToSet()
                    if (this.isExpirePassword(user)) {
                        this.renewPassword = true;
                        this.logoutFrom("http");
                        return;
                    }

                    const isTrainer = !!(
                        user.get("role").findIndex((role) => role === 5) >= 0
                    ); // tester autrement qu'avec un numero (voir authService)
                    // it's first user trainer connexion and help is active
                    if (
                        user.get("access") &&
                        user.get("first_access") === true &&
                        this.settings.askForHelp &&
                        isTrainer
                    ) {
                        this.help();
                    } else {
                        this.navigate();
                    }
                } else {
                    let titleDialog: string;
                    let bodyDialog: string;
                    this.translate
                        .get("generic.role_not_allowed_title")
                        .subscribe((translation: string) => (titleDialog = translation));
                    this.translate
                        .get("generic.role_not_allowed_body")
                        .subscribe((translation: string) => (bodyDialog = translation));
                    const dialogConfig = new MatDialogConfig();
                    dialogConfig.data = {
                        titleDialog: titleDialog,
                        bodyDialog: bodyDialog,
                    };

                    const dialogRef = this.dialog.open(
                        FuseConfirmDialogComponent,
                        dialogConfig
                    );

                    dialogRef.afterClosed().subscribe((result) => {
                        this.router.navigate(["logout"]);
                    });
                }
            });
    }

    /**
     * update password and go to program if no error
     */
    private updatePassword(loginForm): void {
        this.loggedUser.set("password", loginForm.value["newPassword"]);
        this.loggedUser.save().subscribe(
            (userUpdate: DataEntity) => {
                this.renewPassword = false;
                this.navigate();
            },
            (error) => {
                console.log(error);
            }
        );
    }

    /**
     * is the new password need to be set
     */
    private isNewPasswordToSet(loginForm): boolean {
        return (
            this.settings.activeChangePasswordStrategy &&
            loginForm.value["newPassword"] &&
            loginForm.value["newPassword"] !== null &&
            loginForm.value["newPassword"] !== undefined &&
            loginForm.value["newPassword"] !== ""
        );
    }

    /**
     * is user expiration password date is passed
     * @param user: DataEntity
     */
    private isExpirePassword(user: DataEntity): boolean {
        return (
            this.settings.activeChangePasswordStrategy &&
            user.get("expirePassword") &&
            currentTimestamp() >= user.get("expirePassword") &&
            user.get("expirePassword") !== null &&
            user.get("expirePassword") !== undefined
        );
    }

    /**
     * open modal asking if user need help at first connexion
     */
    private help(): void {
        const data = {
            titleDialog: "generic.ask.help",
            bodyDialog: "generic.ask.help.content",
            labelTrueDialog: "generic.yes",
            labelFalseDialog: "generic.no",
        };

        this.translate
            .get(data.titleDialog)
            .subscribe((translation: string) => (data.titleDialog = translation));
        this.translate
            .get(data.bodyDialog)
            .subscribe((translation: string) => (data.bodyDialog = translation));
        this.translate
            .get(data.labelTrueDialog)
            .subscribe((translation: string) => (data.labelTrueDialog = translation));
        this.translate
            .get(data.labelFalseDialog)
            .subscribe(
                (translation: string) => (data.labelFalseDialog = translation)
            );

        let dialogRef = this.dialog.open(FuseConfirmDialogComponent, {
            data: data,
        });

        dialogRef.afterClosed().subscribe((result) => {
            if (result) {
                // help page
                this.router.navigate(["home"]);
            } else {
                // normal navigate
                this.navigate();
            }
            dialogRef = null;
        });
    }

    private navigate(): void {
        let url = defaultLoginRoute;
        const returnParam: string = this.route.snapshot.queryParams["return"];

        // Si la route est définie par l'actuelle url, c'est la route prioritaire
        if (returnParam) {
            url = returnParam;
        } else if (this.isFirstConnexion()) {
            // Sinon si c'est la premiere connexion, on vérifie qu'on a pas une route particulière de bienvenue
            const userRole = this.accessLevel;
            const redirectRules = this.settings.firstConnexionRedirection;
            const forceRedirection = redirectRules.hasOwnProperty(userRole)
                ? redirectRules[userRole]
                : redirectRules.default;
            if (forceRedirection !== undefined) {
                url = forceRedirection;
            }
        }

        this.router.navigateByUrl(url);
    }
}
