import {Component, inject} from '@angular/core';
import {AssignationService} from '@modules/assignation';
import {filter, switchMap, takeUntil, tap} from 'rxjs/operators';
import {BehaviorSubject, shareReplay} from 'rxjs';
import {UserDataEntity} from '@modules/authentication';
import {CommunicationCenterService} from '@modules/communication-center';
import {AssignmentEntity} from '@modules/assignation/core/models/assignment.entity';
import {AutoUnsubscribeTakeUntilClass} from "shared/models";
@Component({
    selector: 'app-my-assignments',
    templateUrl: './my-assignments.component.html',
    styleUrls: ['./my-assignments.component.scss']
})
export class MyAssignmentsComponent extends AutoUnsubscribeTakeUntilClass {
    public isAssignmentsLoading$ = new BehaviorSubject<boolean>(false);

    private assignationService = inject(AssignationService);
    private communicationCenter = inject(CommunicationCenterService);

    private user$ = this.communicationCenter
        .getRoom('authentication')
        .getSubject<UserDataEntity>('userData')
        .pipe(
            filter(userData => !!userData)
        );

    public myAssignments$ = this.user$.pipe(
        tap(() => this.isAssignmentsLoading$.next(true)),
        switchMap(user => this.assignationService.loadPaginatedAssignments({
            filter: {
                'assignated_user': user.id,
                'excludeAssignator': true,
                'assignments_state': 'notclosed'
            }
        })),
        tap(assignments => {
            this.isAssignmentsLoading$.next(false);
            this.communicationCenter
                .getRoom('assignment')
                .getSubject('assignmentsChangedNumber')
                .next(assignments.length);
        }),
        shareReplay(1),
        takeUntil(this.unsubscribeInTakeUntil),
    );

    public identifyAssignment = (index: number, assignment: AssignmentEntity) => assignment.id;

}
