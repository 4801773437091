export const header = {
    ignoreBackButtonCliquedOnHome: true, // Android: quand on clique sur le bouton retour et qu'on et à la racine, quitte l'application
    backRoutes: [{
        // Séparer origin et destination permet de faire un router navigate quand on arrive sur une route sans historique
        'origin': '/lessons/list/lessons',
        'destination': '/lessons/home'
        },
        { // origin path will be compare to current destination and if there are equal return is allowed (:param mean don't compare this part of url)
            'origin': '/lessons/grade/:param/concept/:param/chapter/:param',
            'destination': '/lessons/home'
        },
        {
            // L'utilisation de :param permet au navigation service d'ignorer une partie de l'URL au besoin.
            'origin': '/my-avatar/badge-gallery/:param',
            'destination': '/my-avatar'
        },
    ]
};
